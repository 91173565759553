import React from 'react';

const ChatMessages = ({ 
  thisCb, 
  messages, 
  messagesEndRef, 
  parseTextToJSX, 
  onImageClick,
  isDragging,
  handleDragEnter,
  handleDragLeave,
  handleDrop
}) => {
  return (
    <div 
      className={`chat-messages ${isDragging ? 'dragging' : ''}`} 
      ref={messagesEndRef}
      onDragOver={(e) => e.preventDefault()} // Prevent default to allow drop
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop} // Handle the drop event
    >
      {[
        {
          sender: 'AI',
          text: thisCb.customGreeting || `您好！ 我是 { ${thisCb.cbName} }，歡迎與我對話！ 😀
您可以輸入一個問題，以便讓我了解您的提問。
我目前還在測試學習階段，若無法解決您的疑問，請多多包涵`
        },
        ...messages
      ].map((messageItem, index) => {
        if (messageItem.sender === 'loading') {
          return (
            <div key={`${index}${messageItem.id}`} className="dialog-row received-side">
              <div className="dialog-box">
                <div className="loading-dots">
                  <div className="dot" style={{ '--dot-index': 0 }}></div>
                  <div className="dot" style={{ '--dot-index': 1 }}></div>
                  <div className="dot" style={{ '--dot-index': 2 }}></div>
                </div>
              </div>
            </div>
          );
        }
        return (
          <div 
            key={`${index}${messageItem.id}`} 
            className={
              `dialog-row 
              ${['AI','人工客服'].includes(messageItem.sender)?'received':'sent'}-side
              ${['人工客服'].includes(messageItem.sender)?'clerk-message':undefined}
              `
            }
          >
            {messageItem.sender === "User" && <div className="timer-sent">{messageItem.time}</div>}
            <div className="dialog-box">
              {messageItem.text && <div className="title">{parseTextToJSX(messageItem.text)}</div>}
              {messageItem.imageUrl && (
                <img
                  src={messageItem.imageUrl}
                  alt="Chatbot response"
                  className="chat-image"
                  style={{ marginTop: '2vh', cursor: 'pointer' }}
                  onClick={() => onImageClick(messageItem.imageUrl, messageItem.text)}
                />
              )}
            </div>
            {messageItem.sender !== "User" && <div className="timer-received">{messageItem.time}</div>}
          </div>
        );
      })}
    </div>
  );
};

export default ChatMessages;