import React from 'react';

export const parseTextToJSX = (text) => {
  // Remove 'undefined' from the end of the string if it exists
  const cleanedText = text.replace(/undefined$/, '');
  // Replace LaTeX-style \text{} with the actual content
  let processedText = cleanedText.replace(/\\text\{([^}]+)\}/g, '$1');
  // Replace LaTeX-style \[ ... \] (math expressions) with the content in parentheses
  processedText = processedText.replace(/\\\[(.*?)\\\]/gs, '($1)');
  // Replace \times with the multiplication symbol ×
  processedText = processedText.replace(/\\times/g, '×');
  // Replace \% with the percentage symbol %
  processedText = processedText.replace(/\\%/g, '%');
  
  // Define URL regex pattern (http or https)
  const urlPattern = /https?:\/\/[^\s)）\]，。]+/g;
  // Define Markdown header pattern (e.g., ###)
  const headerPattern = /^(#+) (.+)$/gm;
  // Split text into parts by headers, bold text, URLs, and regular text
  const parts = processedText.split(/(\*\*[^*]+\*\*|https?:\/\/[^\s)）\]，。]+|^#+ .+)/gm);

  return parts.map((part, index) => {

    // Handle Markdown headers
    const headerMatch = part.match(headerPattern);
    if (headerMatch) {
      const headerLevel = headerMatch[1].length;
      const headerContent = headerMatch[2];
      return React.createElement(`h${Math.min(headerLevel, 6)}`, { key: index }, headerContent);
    }

    // Handle bold text
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    }

    // Handle URLs
    if (urlPattern.test(part)) {
      return (
        <a href={part} key={index} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }

    // Regular text (including transformed math expressions)
    return <span key={index} style={{ lineBreak: 'auto' }}>{part}</span>;
  });
};

// Function to check if the current time falls within the customer service schedule
export const isWithinCustomerServiceHours = (customerServiceSchedule) => {
  if (customerServiceSchedule) {
    const currentDate = new Date();
    const currentDay = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
    const currentTime = currentDate.toTimeString().slice(0, 5); // Get current time in 'HH:MM' format

    const scheduleForToday = customerServiceSchedule[currentDay];
    if (!scheduleForToday || !scheduleForToday.start || !scheduleForToday.end) {
      // No schedule for today or invalid times
      return false;
    }

    const { start, end } = scheduleForToday;
    
    // Check if the schedule wraps around midnight
    const wrapsAroundMidnight = start > end;

    if (wrapsAroundMidnight) {
      // If the current time is after the start time OR before the end time
      console.log('currentTime >= start:', currentTime >= start)
      console.log('currentTime <= end:', currentTime <= end);
      return currentTime >= start || currentTime <= end;
    } else {
      // If the schedule is within the same day
      return currentTime >= start && currentTime <= end;
    }
  }
  return true; // Default to true if no schedule is provided
};




