import React, { useEffect, useRef, useState } from "react";
import withRouter from "components/Common/withRouter";
import { 
  Col, 
  Row, 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { BASE_URL } from "utils/index";
import axiosInstance from 'utils/axiosInstance';

import { FaStar } from 'react-icons/fa';
import RatingComponent from './modals/RatingComponent';
import RatingModal from './modals/RatingModal';
 
// WebSocket communication
import { io } from "socket.io-client";

// Custom Utils for Image Uploading
import { dateGen } from 'utils/toolUtils';
import { isValidImage, fetchImage, uploadImg } from 'utils/imageUtils';
import { 
  parseTextToJSX, 
  isWithinCustomerServiceHours 
} from './utils/chatbotUtils';

// Import file icons and images
import placeholderImg from "assets/images/placeholders/placeholder_card.png";

import './Chatbot.css';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import ChatMessagesModalView from './ChatMessagesModalView';

/*---- Example Message ----*/
/*
const messages = [
  { id: 1, text: "Hello, how can I help you?", sender: "received", time: "18:45"  },
  { id: 2, text: "I have a question about my order.", sender: "sent", time: "18:45"  },
  { id: 3, text: "Sure, what is your order number?", sender: "received", time: "18:46"  },
  { id: 4, text: "It's #1234.", sender: "sent", time: "18:46"  }
];
*/

const Chatbot = props => {


  /*---- Props and States ----*/

  const { 
    thisAgent, 
    agentImg, 
    token, 

    headerColor, 
    headerHeight,
    headerTextColor, 
    headerFontSize,
    headerBorderRadius, 

    conversationBoxHeight,
    conversationBoxFontSize,
    conversationBoxTimeFontSize,
    conversationBoxTimeTextColor,
    conversationBoxBackgroundColor,

    userMessageBackgroundColor,
    AIMessageBackgroundColor,
    userMessageTextColor,
    AIMessageTextColor,

    inputFontSize,
    inputTextColor,
    inputMarginColor,
    inputBackgroundColor,
    sendIconSize,
    sendIconColor,
  } = props;

  
  
  if(token){
    // Store token in a global variable when chatbot used in client sites
    window.chatbotAuthToken = token;
  } else {
    // When chatbot used in own platform
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const authToken = authUser?.token;
    window.chatbotAuthToken = authToken;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [conversationId, setConversationId] = useState(null);

  const [isRealTime, setIsRealTime] = useState(false); // Track if real-time customer service is active
  const [socket, setSocket] = useState(null); // Store WebSocket connection

  useEffect(() => {
    // Cleanup function to disconnect the socket when component unmounts
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]); // Runs when the `socket` changes or the component unmounts

  const [message, setMessage] = useState(''); // message within dialog input
  const [messages, setMessages] = useState([]); // the whole set of dialog

  // For conversation scroll to bottom
  const messagesEndRef = useRef(null);
  const setMessagesEndRef = (node) => { messagesEndRef.current = node; }

  // For textarea height adjustment
  const inputTextareaRef = useRef(null); 
  const setInputTextareaRef = (node) => {
    inputTextareaRef.current = node;
    adjustTextareaHeight();
  };

  const [isModalView, setIsModalView] = useState(false);
  const modalMessagesEndRef = useRef(null); // For modal conversation scroll to bottom
  const modalInputTextareaRef = useRef(null); // For modal textarea height adjustment

  let jsonPart='', textPart='';

  /*---- Props and States Tail ----*/



  /*---- For Agent Functionalities ----*/

  const [currentTopic, setCurrentTopic] = useState('');
  const [currentTopicDetail, setCurrentTopicDetail] = useState('');

  const decideTopic = async () => {
    const messageToDecide = [...messages.map(m=>m.text), message];
    //console.log("Deciding current topic...", messageToDecide);
    try{

      const response = await axiosInstance.post(`/knovia/agent-topicdecide/${thisAgent._id}`,
        messageToDecide,
        {  headers: { 'Content-Type': 'application/json' } }
      );
      const { topic } = response.data;
      const cleanedJsonString = topic.replace(/```json|```/g, '').trim();
      const parsedTopicData = JSON.parse(cleanedJsonString);

      setCurrentTopic(parsedTopicData["主題"]);
      setCurrentTopicDetail(parsedTopicData["主題細項"]);
    } catch (e) {
      console.log(e);
    }
  }

  /*---- For Agent Functionalities Tail ----*/





  /*---- Text Window Related Settings ----*/

  // Use useEffect to scroll to the bottom every time messages change
  useEffect(() => {
    if (messagesEndRef.current) { 
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
    //if (modalMessagesEndRef.current) { 
    //  modalMessagesEndRef.current.scrollTop = modalMessagesEndRef.current.scrollHeight;
    //}
  }, [messages]);

  useEffect(() => {
    const setVh = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };
    window.addEventListener('resize', setVh);
    setVh(); // Set the initial value
    return () => window.removeEventListener('resize', setVh);
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const adjustTextareaHeight = () => {
    //console.log('[adjustTextareaHeight] inputTextareaRef.current:', inputTextareaRef.current);
    if (inputTextareaRef.current) {
      inputTextareaRef.current.style.height = "auto"; 
      if(inputTextareaRef.current.scrollHeight>36){
        inputTextareaRef.current.style.height = `${inputTextareaRef.current.scrollHeight}px`;
      } else {
        inputTextareaRef.current.style.height = `${Math.max(40, inputFontSize)}px`;
      }
    }
  };

  // Change in textarea input
  const handleChange = (e) => {
    setMessage(e.target.value);
    adjustTextareaHeight();
  };

  const wordLimit = 100;
  const [customerComment, setCustomerComment] = useState('');
  const clientCommentRef = useRef(null);
  const handleCustomerCommentChange = (e)=>{
    if(e.target.value.length <= wordLimit){
      setCustomerComment(prevComment => e.target.value);
    }
  };

  useEffect(()=>{
    const adjustClientCommentHeight = () => {
      if (clientCommentRef.current) {
        clientCommentRef.current.style.height = "auto"; 
        if(clientCommentRef.current.scrollHeight>100){
          clientCommentRef.current.style.height = `${clientCommentRef.current.scrollHeight}px`;
        } else {
          clientCommentRef.current.style.height = `${Math.max(100, inputFontSize)}px`;
        }
      }
    };
    adjustClientCommentHeight();
  },[customerComment])

  useEffect(()=>{
    adjustTextareaHeight();
  },[isModalView, inputTextareaRef.current])


  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Keep small chat window closed and open large window
  useEffect(()=>{
    if(isOpen && isSmallScreen){
      setIsOpen(false);
      setIsModalView(true);
    }
  },[isOpen, isSmallScreen])


  const toggleChatWindow = () => { setIsOpen(!isOpen) };
  const closeChatWindow = () => { 
    if(conversationId && !selectedRating){
      setIsRatingModalOpen(true);
    }
    setIsOpen(false);
  };

  /*---- Text Window Related Settings ----*/




  const callService = async ()=>{

    if(!conversationId){ return; }

    if (!isRealTime) {

      try {
        const response = await axiosInstance.post('/knovia/agent/call-service', { 
          conversationId: conversationId 
        });
        //console.log('response:', response);
      } catch (error) {
        console.error('Error requesting real customer service:', error);
      }

      // If real-time service is not active, switch to WebSocket
      const newSocket = io(BASE_URL); // Backend WebSocket URL
      
      // Join a room using the conversationId as roomId
      newSocket.emit('joinRoom', { username: "User", room: conversationId });

      // Listen for incoming messages in the room
      newSocket.on("chatMessage", (msg) => {
        // Receive message from WebSocket and update UI
        //console.log('[Chatbot.js] received msg:', msg);
        //console.log('[Chatbot.js] received Message:', msg.message);
        if(msg.sender!=='User'){
          setMessages((prevMessages) => [...prevMessages, 
            {
              conversationId: msg.conversationId,
              sender: "received",
              text: msg.message,
              time: new Date(msg.time).toLocaleTimeString(),
            }
          ]);
        }
      });

      // Set the WebSocket and update the UI
      setSocket(newSocket);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "received", text: "[已為您轉接真人客服...請稍待片刻]" },
      ]);
    } else {
      // If real-time service is active, disconnect WebSocket and switch back to AI
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "received", text: "[已為您轉接至AI客服...]" },
      ]);
    }
    setIsRealTime(!isRealTime); // Toggle real-time service state
  };


  /*---- Conversation with Backend ----*/
  useEffect(()=>{ 
    //console.log('Now messages:', messages) 
  },[messages])

  const sendMessage = async () => {
    if (!message.trim()) return;

    //await decideTopic();

    // Reset the input field after sending the message
    setMessage('');
    if (inputTextareaRef && inputTextareaRef.current && inputTextareaRef.current.style) {
      if(isModalView){
        // Input in large window
        inputTextareaRef.current.style.height = `${Math.max(18, inputFontSize*1.66)}px`;
      } else {
        // Input in small window
        inputTextareaRef.current.style.height = '40px';
      }
    }

    if (message.trim()) {

      /*---- Optimistic Update of user message ----*/

      const tempMessageId = Date.now(); // Temporary ID for optimistic UI update
      const tempBotMessageId = 'tempBot';
      let conversation, processedMessages, botImageMessages;

      let parsedData = {};
      let jsonPart, textPart;
      let optimisticUserMessage={}, optimisticBotMessage={};

      if (isRealTime) {
        optimisticUserMessage = {
          id: tempMessageId,
          text: message,
          sender: 'sent',
          time: new Date().toLocaleTimeString(),
        };
        // Add the optimistic user message
        setMessages(prevMessages => [...prevMessages, optimisticUserMessage]);
      } else {
        optimisticUserMessage = {
          id: tempMessageId,
          text: message,
          sender: 'sent',
          time: new Date().toLocaleTimeString(),
        };

        optimisticBotMessage = {
          id: tempBotMessageId,
          text: message,
          sender: 'loading',
          time: new Date().toLocaleTimeString(),
        };

        // Add the optimistic user message and bot response
        setMessages(prevMessages => [...prevMessages, optimisticUserMessage, optimisticBotMessage]);
      }
      /*---- Optimistic Update of user message ----*/



      /*---- Post and Receive streaming Response ----*/

      if (isRealTime) {
        // If WebSocket is connected, send message through WebSocket
        socket.emit('chatMessage', {
          sender: 'User',
          message,
          room: conversationId,
          time: new Date(),
        });
      } else {
        const data = { 
          sender: 'User',
          message, 
          agentId: thisAgent._id,
          conversationId,
        };

        try {

          const response = await axiosInstance.post(`/knovia/agent/conversations`, data,
            {
              headers: { 'Content-Type': 'application/json' },
              onDownloadProgress: (evt) => {

                // Check if response status is 200/201 before processing response data
                if (evt.event.target.status === 200 || evt.event.target.status === 201) {

                  const currentResponse = evt.event.target.response.replace('undefined', '');


                  // Bot message from backend completed
                  let splitIndex = currentResponse.indexOf('\n\n\n\n');
                  
                  if (splitIndex !== -1) {
                    textPart = currentResponse.substring(0, splitIndex);
                    jsonPart = currentResponse.substring(splitIndex + 4);

                    //console.log('Text Part:', textPart);
                    //console.log('Json Part:', jsonPart);

                    // Get JSON Part
                    try {
                      if (jsonPart.trim()) {
                        const parsedData = JSON.parse(jsonPart);

                        // Assuming parsedData contains the necessary information
                        ({ conversation, processedMessages, botImageMessages } = parsedData);

                        // Check and update conversation ID if needed
                        if (!conversationId && conversation._id) { 
                          setConversationId(conversation._id) 
                        }

                        if(processedMessages){
                          // Update the messages in the UI
                          setMessages(prevMessages => {

                            // Filter out the loading and optimistic messages
                            let updatedMessages = prevMessages.filter(message => message.id !== tempMessageId && message.id !== tempBotMessageId);

                            // Add the user message confirmed by the server
                            if (processedMessages.userMessage) {
                              updatedMessages.push({
                                ...optimisticUserMessage,
                                id: processedMessages.userMessage._id, // Update ID with the one from server
                              });
                            }

                            // Add the new bot message
                            if (processedMessages.botMessage) {
                              updatedMessages.push({
                                id: processedMessages.botMessage._id,
                                text: textPart,
                                sender: 'received',
                                time: new Date().toLocaleTimeString(),
                              });
                            }

                            // Add the bot image messages
                            if (botImageMessages && botImageMessages.length > 0) {
                              
                              botImageMessages.forEach((botImageMessage, index) => {
                                const base64Image = botImageMessage.image.data;
                                //console.log('base64Image:', base64Image.slice(0,100), '...');
                                const imageUrl = `data:${botImageMessage.image.contentType};base64,${base64Image}`;

                                updatedMessages.push({
                                  id: `${processedMessages.botMessage._id}-image-${index}`,
                                  text: botImageMessage.text,
                                  imageUrl: imageUrl,
                                  sender: 'received',
                                  time: new Date().toLocaleTimeString(),
                                });
                              });
                            }

                            return updatedMessages;
                          });
                        }

                      }
                      
                    } catch (e) {
                      console.log('error', e);
                    }

                  // Bot message from backend not completed yet
                  } else {
                    // Update the messages in the UI
                    setMessages(prevMessages => {
                      // Filter out the previous incomlete bot message
                      let updatedMessages = prevMessages.filter(message => message.id !== tempBotMessageId);
                      let newStreamMessage = {};

                      // Add the new bot message
                      if (currentResponse) {
                        newStreamMessage = {
                          id: tempBotMessageId,
                          text: currentResponse,
                          sender: 'received',
                          time: new Date().toLocaleTimeString(),
                        }
                      }

                      return [...updatedMessages, newStreamMessage];
                    });
                  }
                }
              }
            }
          );

          // Check response status before updating final messages
          if (response.status === 200 || response.status === 201) {
            console.log('Stream completed successfully');
          } else {
            console.error('Non-200/201 response received:', response.status);
          }

        } catch (error) {
          console.error('Error fetching stream:', error);
        }

      }

      /*---- Update User Message Tail ----*/

    }

  };
  /*---- Conversation with Backend ----*/


  /*---- Get Service Schedule ----*/
  const [thisServiceSchedule, setThisServiceSchedule] = useState(null);
  const getServiceSchedule = async () => {
    console.log("Getting service schedule...");

    try{
      const response = await axiosInstance.get(`/knovia/agent/service-schedule/${thisAgent._id}`);
      setThisServiceSchedule(response.data.customerServiceSchedule);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if(!thisServiceSchedule && thisAgent && thisAgent._id){
      getServiceSchedule();
    }
  }, [thisAgent]);


  /*---- Modal viewing the images ----*/

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageTitle, setSelectedImageTitle] = useState('');

  const toggleModal = () => setModalOpen(!modalOpen);

  const onImageClick = (imageData, imageTitle='瀏覽圖片') => {
    setSelectedImageTitle(imageTitle);
    setSelectedImage(imageData);
    setModalOpen(true);
  };

  /*---- Modal viewing the images Tail ----*/



  /*---- Drag and drop Images ----*/
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('drag enter');
    setIsDragging(true);  // Add visual feedback by setting dragging state
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('drag leave');
    setIsDragging(false);  // Remove visual feedback when dragging leaves
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    setIsDragging(false);  // Remove visual feedback when drop occurs

    if (files.length > 0) {
      const imageFiles = Array.from(files).filter(file => file.type.startsWith('image/'));
      
      if (imageFiles.length > 0) {
        console.log("Images dropped: ", imageFiles);
        // Handle image upload
        uploadImages(imageFiles);
      }
    }
  };


  /*---- Upload New Image ----*/

  const [imgFile, setImgFile] = useState(null);
  const [imgError, setImgError] = useState('');

  const [imgFilename, setImgFilename] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imgUploadLoading, setImgUploadLoading] = useState(false);
  const [imgUploadStatus, setImgUploadStatus] = useState('');

  const uploadImages = (imageFiles) => {
    setImgUploadStatus('');
    const imgFile = imageFiles[0];
    if (imgFile) {
      console.log("Selected file:", imgFile);
      if (isValidImage(imgFile)) {
        setImgFile(imgFile);
        setImgError('');
      } else {
        setImgError('檔案格式不正確，請上傳 jpg 或 png 檔');
      }
    }
  };

  // Change in uploaded file
  useEffect(() => {

    async function setImageFile(){
      if (imgFile) {
        setImgUploadStatus('');
        const date14string = await dateGen();
        const tempUserMessageId = 'tempUser';
        const newImgFilename = date14string+'_'+imgFile.name;

        try {
          setImgUploadLoading(true);
          const {newImageUrl, uploadStatus} = await uploadImg(newImgFilename, imgFile);
          setImgUploadLoading(false);
          setImgFilename(newImgFilename);
          setImgUploadStatus(uploadStatus);
          const previewImage = await fetchImage(newImgFilename);
          setImageUrl(previewImage);

          const lastUnderscoreIndex = newImgFilename.lastIndexOf('_');
          const showNewImgFilename = newImgFilename.substring(lastUnderscoreIndex + 1);

          setMessages((prevMessages) => [...prevMessages, 
            {
              conversationId: tempUserMessageId,
              sender: 'User',
              text: showNewImgFilename,
              imageUrl: newImageUrl,
              time: new Date().toLocaleTimeString(),
            }
          ]);

        } catch (error) {
          console.log('error:', error);
          setImgUploadLoading(false);
        }

      }
    }

    setImageFile();

  }, [imgFile]);

  /*---- Upload Image Tail ----*/
  /*---- Drag and drop Images ----*/




  /*---- User Rating of the Conversation ----*/

  const [selectedRating, setSelectedRating] = useState(null);
  const [temporateRating, setTemporateRating] = useState(null);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const toggleRatingModal = ()=>{ setIsRatingModalOpen(!isRatingModalOpen); }

  const handleRatingSelected = (rating) => { setTemporateRating(rating); };
  const handleResetRating = () => { setTemporateRating(0); };

  const handleConfirmRating = async () => {
    if(temporateRating>=0 && temporateRating<=5){
      setSelectedRating(temporateRating);
      //console.log(`Confirm selecting rating: ${temporateRating} stars`);

      if(conversationId){
        try {
          await axiosInstance.put(`/knovia/conversation-rating/user/${conversationId}/`, { 
            userRating: temporateRating,
            customerComment: customerComment
          });
        } catch (error) {
          console.error("Error updating user conversation rating", error);
        }
      }
      toggleRatingModal();
    }
  };

  /*---- User Rating of the Conversation ----*/





  /*-------- Main ChatBot Component --------*/


  return (
    <React.Fragment>
      <div className="chatbot-container">


        {/* Small Chatbot Window */}
        <div 
          className={`chat-window 
            ${(isOpen&&!isModalView&&!isSmallScreen) ? 'open' : ''}
            ${isDragging ? 'dragging' : ''}`
          } 
          style={{ 
            right: '15vw', 
            zIndex: '-1',
            borderTopLeftRadius: `${headerBorderRadius}px`,
            borderTopRightRadius: `${headerBorderRadius}px`,
          }}
        >

          {/* Chat Window Header Bar */}
          <div 
            className="chat-header" 
            style={{ 
              display: 'flex', 
              flexDirection: 'column',
              justifyContent: 'center',
              height: `${headerHeight}px`,
              backgroundColor: headerColor,
              borderTopLeftRadius: `${headerBorderRadius}px`,
              borderTopRightRadius: `${headerBorderRadius}px`,
            }}
          >
            <Row style={{ width:'100%', marginLeft:'0px' }}>
              <Col xs={9} 
                style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}
              >
                <p 
                  className="chat-header-text"
                  style={{
                    color: headerTextColor,
                    fontSize: `${headerFontSize}px`,
                    fontWeight: '500'
                  }}
                >
                  {`${thisAgent?.agentName}(測試中)` || ''}
                </p>
              </Col>
              <Col xs={3}
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
              >
                <button className="enlarge-chat" onClick={() => setIsModalView(true)} style={{ display: isModalView ? 'none' : 'block' }}>⤡</button>
                <button className="close-chat" onClick={closeChatWindow}>&times;</button>
              </Col>
            </Row>
          </div>

          {/*
          <div 
            style={{ minHeight: '10vh', marginBottom: '-10vh', background: '#fffdf7',
              zIndex: '0', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0vh 3vw'
            }}
          >
            <p className="mb-0">主題：{currentTopic}</p>
            <p className="mb-0">主題細項：{currentTopicDetail}</p>
          </div>
          */}


          {/* ChatMessages component */}
          <ChatMessages 
            thisAgent={thisAgent}
            messages={messages} 
            messagesEndRef={messagesEndRef} 
            setMessagesEndRef={setMessagesEndRef}
            parseTextToJSX={parseTextToJSX}
            onImageClick={onImageClick}

            isDragging={isDragging}
            handleDragEnter={handleDragEnter}
            handleDragLeave={handleDragLeave}
            handleDrop={handleDrop}

            conversationBoxHeight={conversationBoxHeight}
            conversationBoxFontSize={conversationBoxFontSize}
            conversationBoxTimeFontSize={conversationBoxTimeFontSize}
            conversationBoxTimeTextColor={conversationBoxTimeTextColor}
            conversationBoxBackgroundColor={conversationBoxBackgroundColor}

            userMessageBackgroundColor={userMessageBackgroundColor}
            AIMessageBackgroundColor={AIMessageBackgroundColor}
            userMessageTextColor={userMessageTextColor}
            AIMessageTextColor={AIMessageTextColor}
          />

          {/* The small rating shown at the bottom of the small dialog box */}
          <RatingComponent
            conversationId={conversationId}
            setIsRatingModalOpen={setIsRatingModalOpen}
            selectedRating={selectedRating}

            conversationBoxFontSize={conversationBoxFontSize}
            conversationBoxBackgroundColor={conversationBoxBackgroundColor}
          />

          {/* Pop-up window for customer rating */}
          <RatingModal
            isOpen={isRatingModalOpen}
            toggle={toggleRatingModal}
            temporateRating={temporateRating}
            onRatingSelected={handleRatingSelected}
            onResetRating={handleResetRating}
            customerComment={customerComment}
            onCustomerCommentChange={handleCustomerCommentChange}
            clientCommentRef={clientCommentRef}
            wordLimit={wordLimit}
            headerFontSize={headerFontSize}
            onConfirm={handleConfirmRating}
          />

          <ChatInput 
            thisAgent={thisAgent}
            inputTextareaRef={inputTextareaRef}
            setInputTextareaRef={setInputTextareaRef}
            callService={callService}
            conversationId={conversationId}
            isRealTime={isRealTime}
            isWithinCustomerServiceHours={isWithinCustomerServiceHours}
            isModalView={isModalView}

            message={message}
            handleChange={handleChange}
            handleKeyPress={handleKeyPress}
            sendMessage={sendMessage}

            sendIconColor={sendIconColor}
            sendIconSize={sendIconSize}
            inputFontSize={inputFontSize}
            inputTextColor={inputTextColor}
            inputMarginColor={inputMarginColor}
            inputBackgroundColor={inputBackgroundColor}
          />

        </div>

      </div>


      {/* Chatbot Icon */}
      <button className="chatbot-button" onClick={toggleChatWindow} style={{ right: '1vw', zIndex: '99' }}>
        <img 
          className="chatbot-img" 
          style={{ 
            width: '8rem',
            height: '8rem',
            borderRadius: '30%',
            objectFit: 'cover',
          }} 
          src={ agentImg || placeholderImg } 
          alt="Chat"
        />
      </button>

      <ChatMessagesModalView 
        thisAgent={thisAgent}
        isModalView={isModalView}
        setIsModalView={setIsModalView}
        isWithinCustomerServiceHours={isWithinCustomerServiceHours}
        callService={callService}
        isRealTime={isRealTime}

        conversationId={conversationId}
        setIsRatingModalOpen={setIsRatingModalOpen}
        selectedRating={selectedRating}

        messages={messages}
        messagesEndRef={messagesEndRef}
        setMessagesEndRef={setMessagesEndRef}
        inputTextareaRef={inputTextareaRef}
        setInputTextareaRef={setInputTextareaRef}

        message={message}
        handleChange={handleChange}
        handleKeyPress={handleKeyPress}
        sendMessage={sendMessage}

        parseTextToJSX={parseTextToJSX} 
        onImageClick={onImageClick} 

        headerColor={headerColor}
        headerHeight={headerHeight}
        headerTextColor={headerTextColor}
        headerFontSize={headerFontSize}
        headerBorderRadius={headerBorderRadius}

        conversationBoxHeight={conversationBoxHeight}
        conversationBoxFontSize={conversationBoxFontSize}
        conversationBoxTimeFontSize={conversationBoxTimeFontSize}
        conversationBoxTimeTextColor={conversationBoxTimeTextColor}
        conversationBoxBackgroundColor={conversationBoxBackgroundColor}

        userMessageBackgroundColor={userMessageBackgroundColor}
        AIMessageBackgroundColor={AIMessageBackgroundColor}
        userMessageTextColor={userMessageTextColor}
        AIMessageTextColor={AIMessageTextColor}

        inputFontSize={inputFontSize}
        inputTextColor={inputTextColor}
        inputMarginColor={inputMarginColor}
        inputBackgroundColor={inputBackgroundColor}
        sendIconSize={sendIconSize}
        sendIconColor={sendIconColor}
      />


      {/* Modal Viewing Bot Images */}
      <Modal isOpen={modalOpen} toggle={toggleModal} style={{ display: 'flex', width: 'fit-content', maxWidth: 'fit-content' }}>
        <ModalHeader toggle={toggleModal}
          style={{ padding: '1.5rem 2rem' }}
        >{selectedImageTitle || '瀏覽圖片'}</ModalHeader>
        <ModalBody className="imageModal" style={{ width: 'fit-content', height: 'auto', maxWidth:'75vw', margin: 'auto' }}>
          {selectedImage && (
            <img src={selectedImage} alt="Selected" 
              style={{ maxWidth: '90%', maxHeight: '70vh', margin: '0rem 2rem' }} 
            />
          )}
        </ModalBody>
        <ModalFooter style={{ padding: '1rem 2rem' }}>
          <Button color="secondary" onClick={toggleModal}>關閉</Button>
        </ModalFooter>
      </Modal>


    </React.Fragment>
  );
};

export default withRouter(Chatbot);






